import React from "react"

import "./oral-history-radio-channel-icon.css"

const OralHistoryRadioChannelIcon = () => (
  <div className="icon oral-history-radio-channel-icon">
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 113 113"
      style={{ enableBackground: "new 0 0 113 113" }}
    >
      <g>
        <defs>
          <rect id="SVGID_1_" width="113" height="113" />
        </defs>
        <clipPath id="SVGID_2_">
          <use style={{ overflow: "visible" }} />
        </clipPath>
        <path
          class="temporary-dwellings-fill"
          d="M56.5,113c31.2,0,56.5-25.3,56.5-56.5S87.7,0,56.5,0S0,25.3,0,56.5S25.3,113,56.5,113"
        />
      </g>
    </svg>
    <div>Oral History Radio Channel</div>
  </div>
)

export default OralHistoryRadioChannelIcon
